import React, { useEffect } from "react";
import TemplateDefault from "../../../Templates/TemplateDefault";
import AOS from "aos";
import "aos/dist/aos.css";
import "./PagueMenosStyles.scss";
import Cases from "../../../../../Components/Cases";
import { WhatsAppFloating } from "../../../../../Components/WhatsAppFloating";
import monitorDesktop from "../../../../Media/Site/Pages/Cases/PagueMenos/monitor-1-desktop.webp";
import monitorMobile from "../../../../Media/Site/Pages/Cases/PagueMenos/monitor-1-mobile.webp";
import roleta from "../../../../Media/Site/Pages/Cases/PagueMenos/wheel.webp";
import roletaMob from "../../../../Media/Site/Pages/Cases/PagueMenos/wheel-mobile.webp";
import Init from "../../../../Utils/Initializer";

const Engov = () => {
  Init();
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", function () {
      const scrollPosition = window.scrollY;
      const translateY = scrollPosition * 0.8;
      document.getElementsByClassName("parallax-banner")[0].style.transform =
        "translate3d(0," + translateY + "px,0)";
    });
  });

  return (
    <>
      <TemplateDefault>
        <section className="paguemenos">
          {/* <WhatsAppFloating /> */}

          <div className="parallax">
            <div
              className="parallax-banner flex items-center justify-center"
              id="sonora"
            >
            </div>
          </div>

          <div className="bg-[#001771]">
            <div className="py-12">
              <p
                className="text-white lg:text-[24px] text-[22px] lg:w-[930px] w-[302px] mx-auto lg:mb-10  mb-3 lg:mt-5 lg:leading-[45px]"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                Imagina participar de uma promoção que você não precisa
                preencher
                <br className="lg:hidden" /> um formulário de cadastro.
                <br />
                Isso aconteceu e acontece na parceria que temos com a rede de
                farmácias Pague Menos. Por meio
                <br className="lg:block hidden" />
                de uma conexão API e um trabalho em conjunto com times de
                tecnologia
                <br className="lg:hidden" /> de ambos os lados, conseguimos{" "}
                <br />
                levar benefícios aos consumidores
                <br className="lg:hidden" /> da forma mais simples possível:
                informar o CPF no momento
                <br className="lg:hidden" /> da compra{" "}
                <br className="lg:block hidden" />
                para concorrer automaticamente a mais
                <br className="lg:hidden" /> de R$ 1 milhão em prêmios.
              </p>
            </div>

            <div>
              <picture className="mb-[40px] block lg:mb-0">
                <source
                  media="(max-width: 767px)"
                  srcSet={monitorMobile}
                  alt="Site responsivo"
                ></source>
                <img
                  src={monitorDesktop}
                  alt="Site responsivo"
                  className="lg:w-[1080px] w-[385px] mx-auto"
                />
              </picture>
            </div>

            <div>
              <p
                className="text-white lg:text-[24px] text-[22px] lg:w-[930px] w-[295px] mx-auto mb-10 lg:mt-10 lg:leading-[45px]"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                Com  tanta facilidade, teve muita gente que participou,
                aproveitou
                <br className="lg:hidden" /> e curtiu a festa{" "}
                <br className="lg:block hidden" />
                de 40 anos da marca. Na celebração, rolaram muitos prêmios todos
                os dias, como
                <br />
                carros zero km e cartões-presentes de até R$ 1.000,00.
              </p>
            </div>

            <div
              className="lg:mt-0 lg:pb-[40px] pb-16"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              <picture className="scale-[1.2] lg:scale-[1] block lg:max-w-[1366px] m-auto">
                <source
                  media="(max-width: 767px)"
                  srcSet={roletaMob}
                  alt="Giros na roleta"
                ></source>
                <img
                  className="block m-auto"
                  src={roleta}
                  alt="Giros na roleta"
                />
              </picture>
            </div>

            <div className="paguemenos-flyer">
           
            </div>

          </div>
          <Cases />
        </section>
      </TemplateDefault>
    </>
  );
};
export default Engov;
