import { useEffect } from 'react';
import TemplateDefault from '../../../Templates/TemplateDefault';
import './HondaStyles.scss';
import Cases from '../../../../../Components/Cases';
import AOS from 'aos';
import { WhatsAppFloating } from '../../../../../Components/WhatsAppFloating';
import monitorDesktop from '../../../../Media/Site/Pages/Cases/Honda/monitor-desktop.webp';
import monitorMobile from '../../../../Media/Site/Pages/Cases/Honda/monitor-mobile.webp';
import VideoScrollMobile from '../../../../Media/Site/Pages/Cases/Honda/honda.mp4';
import AnimatedPercentageCounter from './Count';
import Init from '../../../../Utils/Initializer';

const HondaCase = () => {
  Init();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  useEffect(() => {
    document.addEventListener('scroll', function () {
      const scrollPosition = window.scrollY;
      const translateY = scrollPosition * 0.8;
      document.getElementsByClassName('parallax-banner')[0].style.transform =
        'translate3d(0,' + translateY + 'px,0)';
    });
  });

  return (
    <>
      <TemplateDefault>
        <section className="honda">
          {/* <WhatsAppFloating /> */}

          <div className="parallax">
            <div
              className="parallax-banner flex items-center justify-center"
              id="sonora"
            ></div>
          </div>

          <div className="flex justify-center items-center flex-col bg-[#616161]">
            <div className="lg:mt-[127px] mt-[28px] mb-[57px] lg:mb-[94px]">
              <h1 className="lg:text-[24px] text-[22px] text-white lg:w-full w-[296px] lg:leading-[44px] leading-[32px]">
                Com o objetivo de reforçar a filosofia da Honda e despertar o
                sentimento
                <br />
                de pertencimento nos colaboradores, criamos este grande case
                <br className="lg:hidden" /> para incentivar,
                <br className="hidden lg:block" />
                reconhecer
                <br className="lg:hidden" /> e premiar os vendedores da marca.
              </h1>
            </div>
            <div>
              <picture>
                <source
                  media="(max-width: 767px)"
                  srcSet={monitorMobile}
                  alt="Mockup Banco Honda"
                ></source>
                <img
                  src={monitorDesktop}
                  className="lg:w-[950px] w-[320px] mx-auto"
                  alt="Mockup Banco Honda"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                />
              </picture>
            </div>
            <div className="lg:mt-[80px] mt-[40px] lg:mb-[96px] mb-[86px]">
              <h1 className="lg:text-[24px] text-[22px] text-white lg:w-full w-[301px] lg:leading-[44px] leading-[32px]">
                Na mecânica “Atingiu, Girou, Ganhou”, todos tiveram chances de
                conquistas na roleta
                <br className="hidden lg:block" />
                digital e ainda contaram
                <br className="lg:hidden" /> com diversas ações extras em um
                <br className="lg:hidden" /> perfil do Instagram para
                <br />
                aumentarem seus ganhos.
              </h1>
            </div>
          </div>

          <div className="honda-flyer"></div>

          <div className="flex justify-center items-center flex-col bg-[#EF2135]">
            <div className="lg:mt-[96px] mt-[57px] mb-[47px]">
              <h1 className="lg:text-[24px] text-[22px] text-white lg:w-full w-[275px] lg:leading-[44px] leading-[32px]">
                O sucesso foi tanto que o próprio público pediu mais 2 edições
                <br className="lg:hidden" /> (até agora),
                <br className="hidden lg:block" />
                o que nos levou
                <br className="lg:hidden" /> a um dos resultados mais incríveis
                <br className="lg:hidden" /> em campanhas de incentivo:
              </h1>
            </div>
            <div className="flex flex-col justify-center items-center lg:mt-[70px] gap-12">
              <div className="grid justify-center items-start grid-cols-2 lg:grid-cols-3 gap-y-[4rem] lg:gap-x-[5rem] gap-x-8">
                <div className="flex justify-center items-center flex-col">
                  <AnimatedPercentageCounter value={94} />
                  <p className="text-[26px] text-white leading-tight text-center">
                    Aceitação
                    <br />
                    do público.
                  </p>
                </div>
                <div className="flex justify-center items-center flex-col lg:ml-[1.4rem]">
                  <AnimatedPercentageCounter value={97} />
                  <p className="text-[26px] text-white leading-tight text-center">
                    Compreenderam
                    <br />a mecânica.
                  </p>
                </div>
                <div className="flex justify-center items-center flex-col">
                  <AnimatedPercentageCounter value={91} />
                  <p className="text-[26px] text-white leading-tight text-center">
                    Aprovaram
                    <br className="lg:block hidden" /> a
                    <br className="lg:hidden" /> navegabilidade.
                  </p>
                </div>
                <div className="flex justify-center items-center flex-col">
                  <AnimatedPercentageCounter value={94} />
                  <p className="text-[26px] text-white leading-tight text-center">
                    Classificaram a
                    <br />
                    comunicação
                    <br className="lg:hidden" /> como{' '}
                    <br className="lg:block hidden" />
                    muito boa.
                  </p>
                </div>
                <div className="flex justify-center items-center flex-col">
                  <AnimatedPercentageCounter value={90} />
                  <p className="text-[26px] text-white leading-tight text-center lg:w-full w-[128px]">
                    Classificaram como ótima <br className="lg:block hidden" />a
                    diversidade de opções <br className="lg:block hidden" />
                    do Beat Points.
                  </p>
                </div>
                <div className="flex justify-center items-center flex-col">
                  <AnimatedPercentageCounter value={99} />
                  <p className="text-[26px] text-white leading-tight text-center lg:w-full w-[136px]">
                    Afirmaram que a troca <br className="lg:block hidden" />
                    de pontos por prêmios <br className="lg:block hidden" />
                    os motivaram
                    <br className="lg:hidden" /> a trabalhar.
                  </p>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center w-full">
              <video
                className="max-w-[544px] mt-[80px] lg:mt-[120px] mb-[80px] lg:mb-[100px] w-full"
                loop
                autoPlay
                playsInline
                muted
              >
                <source src={VideoScrollMobile} type="video/mp4" />
                Seu navegador não suporta o elemento de vídeo.
              </video>
            </div>
          </div>

          <Cases />
        </section>
      </TemplateDefault>
    </>
  );
};

export default HondaCase;
