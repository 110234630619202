import React, { useEffect, useState } from "react";
import TemplateDefault from "../../../Templates/TemplateDefault";
import AOS from "aos";
import "aos/dist/aos.css";
import "./EngovStyles.scss";
import Cases from "../../../../../Components/Cases";
import { WhatsAppFloating } from "../../../../../Components/WhatsAppFloating";
import Init from "../../../../Utils/Initializer";

const Engov = () => {
  Init();
  const [isMacintosh, setIsMacintosh] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", function () {
      const scrollPosition = window.scrollY;
      const translateY = scrollPosition * 0.8;
      document.getElementsByClassName("parallax-banner")[0].style.transform =
        "translate3d(0," + translateY + "px,0)";
    });
  });

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    setIsMacintosh(userAgent.indexOf("Macintosh") > -1);
  }, []);

  return (
    <>
      <TemplateDefault>
        <section className="engov">
          {/* <WhatsAppFloating /> */}

          <div className="parallax">
            <div className="parallax-banner flex items-center justify-center" id="sonora">
            </div>
          </div>

          <div className="bg-white">
            <div className="py-12">
              <p
                className="text-[#2D294B] lg:text-[24px] text-[22px] lg:w-[758px] w-[290px] mx-auto mb-10 lg:leading-[45px]"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                O dia que a gente entrou na casa mais vigiada do Brasil, foi com
                o Bonde da Curtição Engov After. Uma promo de tiro curto durante
                o carnaval, que gerou muitos leads em questão de segundos, com
                uma ajudinha de Tadeu Schmidt e os Brothers e as Sisters
                <br /> do BBB 2023.
              </p>
              <p
                className="text-[#2D294B] lg:text-[24px] text-[22px] lg:w-[758px] w-[290px] mx-auto mb-10 lg:leading-[45px]"
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                A participação foi simples e descomplicada para ir direto à
                curtição: era só se cadastrar para concorrer a mais de 1.000
                prêmios de até R$ 500,00 na hora, além de uma experiência
                incrível no Camarote Salvador para o ganhador e mais 3 amigos.
              </p>
              <p
                className="text-[#2D294B] lg:text-[24px] text-[22px] font-bold lg:w-[758px] w-[290px] mx-auto"
                data-aos="fade-right"
                data-aos-duration="2500"
              >
                Dá uma espiadinha no que desenvolvemos para formar esse bonde!
              </p>
            </div>
          </div>

          <div className={`flex items-center justify-center pb-[60px] lg:pb-[0px] pt-[80px] lg:pt-0 w-full ${isMacintosh? "bg-[hsl(27.76,92.24%,58.00%)]" : "bg-[hsl(27.76,92.24%,54.51%)]"}`}>
            <iframe
                src="https://player.vimeo.com/video/913819296?h=913819296&autoplay=1&loop=1&autopause=0&background=1&mute=1&quality=2k"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                className="max-w-[1050px] h-[1077px] w-full hidden lg:block"
              ></iframe>
            <iframe
                src="https://player.vimeo.com/video/913819324?h=913819324&autoplay=1&loop=1&autopause=0&background=1&mute=1&quality=1080p"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                className="lg:hidden w-[390px] h-[450px]"
              ></iframe>
          </div>

          <div className="engov-party">
            
          </div>

          <div className="engov-wheel bg-[#0270bc]">
           
          </div>

          <div className="engov-salvador">
          </div>

          <Cases />
        </section>
      </TemplateDefault>
    </>
  );
};
export default Engov;
