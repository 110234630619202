import React, { useEffect } from "react";
import TemplateDefault from "../../../Templates/TemplateDefault";
import AOS from "aos";
import "aos/dist/aos.css";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import "./NeoquimicaStyles.scss";
import hand from "../../../../Media/Site/Pages/Cases/Neoquimica/hand.webp";
import handMob from "../../../../Media/Site/Pages/Cases/Neoquimica/hand-mobile.webp";
import Cases from "../../../../../Components/Cases";
import { WhatsAppFloating } from "../../../../../Components/WhatsAppFloating";
import promoNeoquimicaVideo from "../../../../Media/Site/Pages/Cases/Neoquimica/NmotivosParaGanhar_NeoQuimica.mp4";
import Init from "../../../../Utils/Initializer";

const Neoquimica = () => {
  Init();
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", function () {
      const scrollPosition = window.scrollY;
      const translateY = scrollPosition * 0.8;
      document.getElementsByClassName("parallax-banner")[0].style.transform =
        "translate3d(0," + translateY + "px,0)";
    });
  });

  useEffect(() => {
    Fancybox.bind("[data-fancybox]");
  }, []);

  return (
    <>
      <TemplateDefault>
        <section className="neoquimica">
          {/* <WhatsAppFloating /> */}

          <div className="parallax">
            <div
              className="parallax-banner flex items-center justify-center"
              id="sonora"
            >
            </div>
          </div>

          <div className="bg-[#01065E]">
            <div className="py-12">
              <h1
                className="text-white lg:text-[24px] text-[22px] lg:w-[675px] w-[316px] mx-auto lg:leading-[45px]"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                1 ano de produtos para a saúde;
                <br className="lg:hidden" /> sem necessidade de comprovação
                <br className="lg:hidden" /> de compra; aceleradores para
                <br className="lg:hidden" /> aumento de chances no sorteio;
                <br className="lg:hidden" /> SÓ SE CADASTRAR PARA CONCORRER…
                Nessa promo, realmente, oferecemos
                <br className="lg:hidden" /> ao público inúmeros motivos
                <br className="lg:hidden" /> pra participar!
              </h1>
            </div>
          </div>

          <div className="neoquimica-promo">
          </div>

          <div className="bg-[#247BEC]">
            <div className="lg:w-[675px] w-[307px] mx-auto lg:py-12 py-5">
              <h1
                className="text-white lg:text-[24px] text-[22px] mx-auto text-left font-bold lg:mb-12 mb-5"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                Já a Neo Química, teve
                <br className="lg:hidden" /> N MOTIVOS para celebrar:
              </h1>
              <ul className="lg:w-[775px] w-[272px] mx-auto">
                <li data-aos="fade-right" data-aos-duration="1800">
                  Mais de 150 mil leads gerados;
                </li>
                <li data-aos="fade-right" data-aos-duration="2000">
                  Aumento de sell-out de
                  <br className="lg:hidden" /> produtos foco;
                </li>
                <li data-aos="fade-right" data-aos-duration="2200">
                  Pesquisa de consumo com
                  <br className="lg:hidden" /> adesão de 97% dos cadastrados;
                </li>
                <li data-aos="fade-right" data-aos-duration="2400">
                  Reforço do posicionamento
                  <br className="lg:hidden" /> institucional para o target;
                </li>
                <li data-aos="fade-right" data-aos-duration="2600">
                  Integração de todo time da marca por meio de ações de
                  incentivo
                  <br className="lg:hidden" /> e endomarketing;
                </li>
                <li data-aos="fade-right" data-aos-duration="2800">
                  Campanhas para redes sociais;
                </li>
                <li data-aos="fade-right" data-aos-duration="3000">
                  E muito mais!
                </li>
              </ul>
            </div>
          </div>

          <div className="neoquimica-products">
          </div>

          <div className="neoquimica-card relative bg-white mt-10 lg:mt-[0px]">
            <div className="flex lg:flex-row flex-col justify-center items-center mx-auto lg:pt-[30px]">
              <div>

            <video
              className="w-[421px] h-[620px] lg:w-[509px] lg:h-[764px] relative z-10"
              loop
              autoPlay
              playsInline
              muted
            >
              <source src={promoNeoquimicaVideo} type="video/mp4" />
              Seu navegador não suporta o elemento de vídeo.
            </video>
              </div>
              <div className="flex items-end lg:mt-[230px] lg:-ml-[70px]">
                <picture>
                  <source
                    media="(max-width: 767px)"
                    srcSet={handMob}
                    alt="Promoção Neo Química N Motivos para ganhar"
                  ></source>
                  <img
                    className="block m-auto relative"
                    src={hand}
                    width={624}
                    alt="Promoção Neo Química N Motivos para ganhar"
                  />
                </picture>
              </div>
            </div>
          </div>

          <Cases />
        </section>
      </TemplateDefault>
    </>
  );
};
export default Neoquimica;
