import { useEffect } from "react";
import TemplateDefault from "../../../Templates/TemplateDefault";
import "./BridgestoneStyles.scss";
import Cases from "../../../../../Components/Cases";
import Aos from "aos";
import { WhatsAppFloating } from "../../../../../Components/WhatsAppFloating";
import muralDesktop from "../../../../Media/Site/Pages/Cases/Bridgestone/mural-desktop.webp";
import muralMobile from "../../../../Media/Site/Pages/Cases/Bridgestone/mural-mobile.webp";
import impressosDesktop from "../../../../Media/Site/Pages/Cases/Bridgestone/impressos-desk.webp";
import impressosMobile from "../../../../Media/Site/Pages/Cases/Bridgestone/impressos-mob.webp";
import eventoPremiacaoDesktop from "../../../../Media/Site/Pages/Cases/Bridgestone/evento-premiacao-desk.webp";
import eventoPremiacaoMobile from "../../../../Media/Site/Pages/Cases/Bridgestone/evento-premiacao-mob.webp";
import comunicationPhoto1 from "../../../../Media/Site/Pages/Cases/Bridgestone/digital-comunication-1.webp";
import comunicationPhoto2 from "../../../../Media/Site/Pages/Cases/Bridgestone/digital-comunication-2.webp";
import comunicationPhoto3 from "../../../../Media/Site/Pages/Cases/Bridgestone/digital-comunication-3.webp";
import comunicationPhoto4 from "../../../../Media/Site/Pages/Cases/Bridgestone/digital-comunication-4.webp";
import Init from "../../../../Utils/Initializer";

const BridgestoneCase = () => {
  Init();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    Aos.init({
      duration: 2000,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", function () {
      const scrollPosition = window.scrollY;
      const translateY = scrollPosition * 0.8;
      document.getElementsByClassName("parallax-banner")[0].style.transform =
        "translate3d(0," + translateY + "px,0)";
    });
  });

  return (
    <>
      <TemplateDefault>
        <section className="bridgestone">

          {/* <WhatsAppFloating /> */}

          <div className="parallax">
            <div
              className="parallax-banner flex items-center justify-center"
              id="sonora"
            >
              
            </div>
          </div>

          <div className="bridgestone-description flex justify-center items-center relative pt-[19px] pl-[19px] pb-[0px]">
            <div className="flex flex-col">
              <p
                className="text-[22px] lg:text-[24px] lg:leading-[34px] leading-[32px] my-[19px] lg:my-[30px]"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                A Melhores Bridgestone é uma campanha{" "}
                <br className="lg:hidden" />
                anual da marca que busca estreitar <br className="lg:hidden" />
                o relacionamento com
                <br className="hidden lg:block" /> os vendedores{" "}
                <br className="lg:hidden" />
                da rede, despertar o orgulho de <br className="lg:hidden" />
                pertencimento nos parceiros e estimular{" "}
                <br className="lg:hidden" />
                a criatividade <br className="hidden lg:block" /> dos
                participantes, através <br className="lg:hidden" />
                de um concurso de vídeos divertido e que{" "}
                <br className="lg:hidden" />
                teve, na última edição, como principal <br />
                premiação uma experiência exclusiva <br className="lg:hidden" />
                na Neo Química Arena com grandes <br className="lg:hidden" />
                nomes do futebol brasileiro.
              </p>
              <span
                className="text-[22px] lg:text-[24px] lg:mb-[30px] mb-[19px] lg:leading-[34px] leading-[32px]"
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                Em 2021/22, entramos em campo<br />{" "}
                com muitas novidades para a ação:{" "}
              </span>
            </div>
          </div>

          <div className="bridgestone-services lg:mb-[30px] mb-5 flex items-center justify-center">
            <ul
              data-aos="fade-right"
              data-aos-duration="2500"
              className="lg:px-[30px] py-[19px] lg:py-[23px] list-none flex flex-col gap-[10px]"
            >
              <li>
                <p>
                  Mecânica gamificada com escolha <br />
                  de temas e formatos de vídeos;
                </p>
              </li>
              <li>
                <p>
                  Conquista de títulos de acordo <br />
                  com o desempenho;
                </p>
              </li>
              <li>
                <p>
                  Prêmios antes, durante e depois <br />
                  do período vigente;
                </p>
              </li>

              <li>
                <p>Contratação de influencers;</p>
              </li>
              <li>
                <p>
                  Comunicação e sustentação <br />
                  ativas por meio de peças estáticas <br />e vídeos;
                </p>
              </li>
              <li>
                <p>
                  API com site da campanha por <br />
                  meio de app Bridgestone;
                </p>
              </li>
              <li>
                <p>
                  Site público com mural de vídeos <br />
                  para votação e controle antifraude;
                </p>
              </li>
              <li>
                <p>
                  Produção e operação de evento <br />
                  na Neo Química Arena;
                </p>
              </li>
              <li>
                <p>E muito mais!</p>
              </li>
            </ul>
          </div>

          <div className="flex items-center justify-center ">
            <iframe
              src="https://player.vimeo.com/video/899335567?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&muted=1&autoplay=1"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
              className="mx-auto"
              title="Bridgestone_MelhoresBS_VideoMelhoresMomentosEvento_1306_V2"
            ></iframe>
          </div>

          <div className="bridgestone-participants lg:flex lg:items-center lg:justify-center">
            <div className="lg:w-[905px]">
              <h3 className="lg:!px-0 title mb-2 leading-tight">
                SITE PARTICIPANTES <br className="lg:hidden" />
                (CONEXÃO API)
              </h3>
              <div className="h-[302px] lg:h-[480px] w-full" />
            </div>
          </div>

          <div className="bridgestone-mural relative ">
            <div className="lg:flex lg:flex-col lg:items-center lg:justify-center">
              <h3 className="lg:w-[905px] lg:!px-0 lg:mt-[34px] title leading-tight">
                MURAL DE VÍDEOS
                <br className="lg:hidden" /> ONLINE PARA VOTAÇÃO
              </h3>
              <picture className="lg:max-w-[1088px] lg:mt-[16px]">
                <source srcSet={muralMobile} media="(max-width: 768px)" />
                <source srcSet={muralDesktop} media="(min-width: 768px)" />
                <img src={muralDesktop} alt="Mural de vídeos" />
              </picture>
            </div>
          </div>

          <div className="bridgestone-comunication lg:flex lg:flex-col lg:items-center lg:justify-center">
            <h3 className="lg:w-[905px] lg:!px-0 lg:my-2 title">
              COMUNICAÇÃO DIGITAL
            </h3>

            <div className="bridgestone-comunication-wrap">
              <div>
                <picture>
                  <img src={comunicationPhoto1} alt="" />
                </picture>
              </div>
              <div>
              <picture>
                  <img src={comunicationPhoto2} alt="" />
                </picture>
              </div>
              <div>
              <picture>
                  <img src={comunicationPhoto3} alt="" />
                </picture>
              </div>
              <div>
              <picture>
                  <img src={comunicationPhoto4} alt="" />
                </picture>
              </div>
            </div>
            
          </div>

          <div className="bridgestone-material lg:flex lg:flex-col lg:items-center lg:justify-center lg:mb-6 mb-4">
            <h3 className="lg:w-[905px] lg:!px-0 title">MATERIAL IMPRESSO</h3>
            <picture className="lg:max-w-[1366px] lg:mt-[-35px]">
              <source srcSet={impressosMobile} media="(max-width: 768px)" />
              <source srcSet={impressosDesktop} media="(min-width: 768px)" />
              <img src={impressosDesktop} alt="Material impresso" />
            </picture>
          </div>

          <div className="bridgestone-events lg:flex lg:flex-col lg:items-center lg:justify-center">
            <h3 className="lg:w-[905px] lg:!px-0 lg:!pt-[25px] lg:!pb-0 title">
              EVENTO DE PREMIAÇÃO
            </h3>

            <picture className="lg:max-w-[1370px] pb-[30px] lg:pb-10">
              <source
                srcSet={eventoPremiacaoMobile}
                media="(max-width: 768px)"
              />
              <source
                srcSet={eventoPremiacaoDesktop}
                media="(min-width: 768px)"
              />
              <img src={eventoPremiacaoDesktop} alt="Evento de premiação" />
            </picture>
            
          </div>
          <Cases />
        </section>
      </TemplateDefault>
    </>
  );
};

export default BridgestoneCase;
