import TemplateDefault from "../../../Templates/TemplateDefault";
import "./BvStyles.scss";
import Cases from "../../../../../Components/Cases";
import { useEffect } from "react";
import AOS from "aos";
import { WhatsAppFloating } from "../../../../../Components/WhatsAppFloating";
import phoneLeft from "../../../../Media/Site/Pages/Cases/Bv/phone-left.webp";
import phoneRight from "../../../../Media/Site/Pages/Cases/Bv/phone-right.webp";
import phoneLeftMobile from "../../../../Media/Site/Pages/Cases/Bv/phone-left-mobile.webp";
import phoneRightMobile from "../../../../Media/Site/Pages/Cases/Bv/phone-right-mobile.webp";
import monitorBV1 from "../../../../Media/Site/Pages/Cases/Bv/monitor-1-bv.webp";
import monitorBV1Mobile from "../../../../Media/Site/Pages/Cases/Bv/monitor-1-bv-mobile.webp";
import monitorBV2 from "../../../../Media/Site/Pages/Cases/Bv/monitor-2-bv.webp";
import monitorBV2Mobile from "../../../../Media/Site/Pages/Cases/Bv/monitor-2-bv-mobile.webp";
import monitorDesktop from "../../../../Media/Site/Pages/Cases/Bv/description-img-1-desktop.webp";
import monitorMobile from "../../../../Media/Site/Pages/Cases/Bv/description-img-1-mobile.webp";
import Init from "../../../../Utils/Initializer";
const Bv = () => {
  Init();
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", function () {
      const scrollPosition = window.scrollY;
      const translateY = scrollPosition * 0.8;
      document.getElementsByClassName("parallax-banner")[0].style.transform =
        "translate3d(0," + translateY + "px,0)";
    });
  });
  return (
    <TemplateDefault>
      <section className="bv">
        {/* <WhatsAppFloating /> */}
        <div className="parallax">
          <div
            className="parallax-banner flex items-center justify-center"
            id="sonora"
          ></div>
        </div>
        <div className="flex flex-col justify-center items-center bg-[#2B42A5] lg:pb-8 pb-5">
          <p
            className="lg:leading-[44px] leading-[32px] lg:text-[24px] text-[22px] text-white pb-10 lg:pt-16 pt-6"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            Sabe aquela sensação gostosa de <br className="lg:hidden" />
            dever cumprido? Foi isso que essa <br className="lg:hidden" />
            promoção que fizemos <br className="hidden lg:block" />
            em parceria <br className="lg:hidden" /> com o time do banco BV
            proporcionou <br className="lg:hidden" />
            ao público. Todos os clientes que <br className="lg:hidden" />{" "}
            adquiriram <br className="hidden lg:block" /> financiamentos de{" "}
            <br className="lg:hidden" />
            veículos no período de vigência, <br className="lg:hidden" />{" "}
            participaram automaticamente de <br className="lg:hidden" />
            sorteios <br className="hidden lg:block" /> de 1 carro quitado por
            dia.
          </p>
          <div>
            <picture>
              <source
                media="(max-width: 768px)"
                srcSet={monitorMobile}
                type="image/webp"
              />
              <img
                src={monitorDesktop}
                alt="Monitor com a descrição da campanha"
                className="lg:w-[1200px] w-[385px] m-auto"
                data-aos="fade-up"
                data-aos-duration="1500"
              />
            </picture>
          </div>
        </div>

        <div className="relative bg-[#2B42A5] pb-12">
          <p
            className="lg:pl-0 flex justify-center lg:leading-[44px] leading-[32px] lg:text-[24px] text-[22px] text-white lg:pb-16 pb-7 lg:pt-0 pt-5"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            Para o projeto, assumimos todo <br className="lg:hidden" />{" "}
            desdobramento tecnológico e criativo <br className="lg:hidden" />
            para desenvolver um site intuitivo, <br /> moderno e inspirado nas
            tendências <br className="lg:hidden" /> de design UX e UI. E
            enquanto <br className="lg:hidden" /> o Felipe Andreoli estrelou{" "}
            <br className="lg:hidden" /> a campanha,{" "}
            <br className="hidden lg:block" />
            nós estávamos <br className="lg:hidden" /> no backstage, cuidando de
            toda <br className="lg:hidden" />
            regularização jurídica e necessidades <br className="lg:hidden" />{" "}
            legais para garantir <br className="hidden lg:block" /> a satisfação{" "}
            <br className="lg:hidden" /> do banco e seus clientes.
          </p>
          <div className="lg:mt-4 lg:mb-8">
            <div className="grid grid-cols-2  gap-[15px] justify-center max-w-[300px] lg:max-w-[950px] m-auto lg:mb-[103px]">
              <div>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={phoneLeftMobile}
                    type="image/webp"
                  />
                  <img
                    data-aos="fade-right"
                    src={phoneLeft}
                    alt="Telefone mostra a tela de contrate e sessão AUMENTE SUAS CHANCES"
                    className="lg:w-[379px] w-[260px] mx-auto"
                  />
                </picture>
              </div>
              <div>
                <picture>
                  <source
                    media="(max-width: 768px)"
                    srcSet={phoneRightMobile}
                    type="image/webp"
                  />
                  <img
                    data-aos="fade-left"
                    src={phoneRight}
                    alt="Telefone mostra a tela de prêmios e premiação"
                    className="lg:w-[379px] w-[260px] mx-auto"
                  />
                </picture>
              </div>
            </div>
          </div>
          <div className="lg:mt-0 mt-8">
            <picture className="flex justify-end relative right-[-55px] lg:right-[-220px] overflow-hidden">
              <source media="(max-width: 768px)" srcSet={monitorBV1Mobile} />
              <img
                src={monitorBV1}
                className="lg:w-[1292px] w-[390px]"
                data-aos="fade-left"
                data-aos-duration="1500"
              />
            </picture>
          </div>
          <div className="lg:mt-12 mt-4">
            <picture className="flex justify-start relative left-[-55px] lg:left-[-180px] mb-[-15px] lg:mb-[-40px] overflow-hidden">
              <source media="(max-width: 768px)" srcSet={monitorBV2Mobile} />
              <img
                src={monitorBV2}
                className="lg:w-[1292px] w-[390px]"
                data-aos="fade-right"
                data-aos-duration="1500"
              />
            </picture>
          </div>
        </div>

        <Cases />
      </section>
    </TemplateDefault>
  );
};

export default Bv;
