import React, { useEffect } from "react";
import TemplateDefault from "../../../Templates/TemplateDefault";
import AOS from "aos";
import "aos/dist/aos.css";
import "./YamahaStyles.scss";
import Cases from "../../../../../Components/Cases";
import { WhatsAppFloating } from "../../../../../Components/WhatsAppFloating";
import post from "../../../../Media/Site/Pages/Cases/Yamaha/photos-desktop.webp";
import postMobile from "../../../../Media/Site/Pages/Cases/Yamaha/photos-mobile.webp";
import thumbnailVideo from "../../../../Media/Site/Pages/Cases/Yamaha/video.webp";
import Init from "../../../../Utils/Initializer";

const Yamaha = () => {
  Init();
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true,
    });
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", function () {
      const scrollPosition = window.scrollY;
      const translateY = scrollPosition * 0.8;
      document.getElementsByClassName("parallax-banner")[0].style.transform =
        "translate3d(0," + translateY + "px,0)";
    });
  });

  return (
    <>
      <TemplateDefault>
        <section className="yamaha">
          {/* <WhatsAppFloating /> */}

          <div className="parallax">
            <div
              className="parallax-banner flex items-center justify-center"
              id="sonora"
            >
            </div>
          </div>

          <div className="bg-[#022848]">
            <div className="lg:pt-[77px] pt-12 lg:pb-[97px] pb-12">
              <p
                className="text-white lg:text-[24px] text-[22px] lg:w-[730px] w-[300px] mx-auto lg:leading-[45px]"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                Há mais de 4 anos, a Yamaha Motos <br className="lg:hidden" /> realiza suas principais campanhas<br className="lg:hidden" /> de incentivo <br className="hidden lg:block" />
de vendas com a gente.<br className="lg:hidden" /> Em cada projeto, aplicamos inovações<br className="lg:hidden" /> e novidades, seja <br className="hidden lg:block" />
em conceitos<br className="lg:hidden" /> criativos, mecânicas, navegabilidade<br className="lg:hidden" /> e principalmente nas premiações. <br className="hidden lg:block" />
              </p>
            </div>
          </div>

          <div className="yamaha-flyer">
            
          </div>

          <div className="bg-[#022848] lg:pt-[83px] pt-[51px] lg:pb-[6rem] pb-16">
            <div data-aos="fade-right" data-aos-duration="1500">
              <p className="text-white lg:text-[24px] text-[22px] lg:w-[932px] w-[302px] mx-auto lg:mb-10 mb-8 lg:leading-[45px]">
              Nos projetos desenvolvidos para<br className="lg:hidden" /> a marca, já recompensamos o público<br className="lg:hidden" /> com pontos para resgate de prêmios, <br />
créditos em cartões pré-pagos<br className="lg:hidden" /> e viagens para destinos nacionais<br className="lg:hidden" /> e internacionais, como Porto<br className="lg:hidden" /> de Galinhas, <br className="hidden lg:block" />
Foz do Iguaçu,<br className="lg:hidden" /> Argentina e Paraguai. 
              </p>
              <p className="text-white lg:text-[24px] text-[22px] lg:w-[932px] w-[302px] mx-auto lg:mb-20 mb-16">
              Confira tudo o que preparamos<br className="lg:hidden" /> e como foi a última premiação<br className="lg:hidden" /> que fizemos, com destino a Manaus.
              </p>
            </div>

            <div>
              <iframe
                src="https://player.vimeo.com/video/911279997?h=9a0af1ebea&muted=1&autoplay=1"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                className="mx-auto"
              ></iframe>
            </div>

            <div className="lg:mt-[115px] mt-16">
              <picture>
                <source media="(max-width: 768px)" srcSet={postMobile} />
                <img
                  src={post}
                  alt="Ganhadores"
                  className="block mx-auto lg:w-[1150px] w-[348px]"
                />
              </picture>
            </div>

            <div>
              <iframe
                src="https://player.vimeo.com/video/911279967?h=9a0af1ebea&muted=1&autoplay=0"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                className="mx-auto block mx-auto lg:w-[1094px] w-[348px] mt-[34px] lg:mt-[94px]"
              ></iframe>
            </div>

          </div>

          <Cases />
        </section>
      </TemplateDefault>
    </>
  );
};
export default Yamaha;
